<template>
  <el-dialog
    title="变更记录"
    :visible.sync="visible"
    width="1000px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="table">
        <div class="flex flex-center table-header">
          <div class="cell" style="width: 210px">操作时间</div>
          <div class="cell" style="width: 120px">操作人</div>
          <div class="cell" style="width: 120px">操作类型</div>
          <div class="cell" style="width: 120px">变更字段</div>
          <div class="cell flex-1">变更明细</div>
          <div class="cell" style="width: 120px">备注原因</div>
        </div>
        <div class="scroll-div" v-infinite-scroll="load">
          <div class="flex flex-center item" v-for="(item, index) in list" :key="index" v-show="item.list.length > 0">
            <div class="cell" style="width: 210px">{{item.createTime}}</div>
            <div class="cell" style="width: 120px">{{item.operatorName}}</div>
            <div class="cell" style="width: 120px">{{typeMap[item.operateType]}}</div>
            <div class="flex-1">
              <div class="flex flex-center" v-for="(o, i) in item.list" :key="i">
                <div class="cell" style="width: 120px">{{o.operateColum}}</div>
                <div class="cell flex-1" v-html="o.operateDetail"></div>
              </div>
            </div>
            <div class="cell" style="width: 120px">{{item.remark}}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { get_key_value } from '@/api/common'
import { get_hotel_operate_log } from '@/api/hotel'

export default {
  mixins: [pageMixin],
  props: {
    visible: {
      type: Boolean
    },
    hotelId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [],
      typeOption: [],
      typeMap: {}
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.pageParams = {
          pageNo: 1,
          size: 10
        }
        this.getList()
      } else {
        this.list = []
      }
    }
  },
  mounted() {
    this.getOption()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'HOTEL_OPERATE_TYPE'
      }).then(res => {
        this.typeOption = res ? res.records : []
        this.typeMap = this.$arrayToMap(this.typeOption)
      })
    },
    getList() {
      if (!this.hotelId) return
      get_hotel_operate_log({
        ...this.pageParams,
        hotelId: this.hotelId
      }).then(res => {
        if (res) {
          if (this.pageParams.pageNo == 1) {
            this.list = res.records
          } else {
            this.list = this.list.concat(res.records)
          }
        }
        this.totalItems = res ? res.total : 0
        let obj = {}
        this.list.map(o => {
          if (o.batchId && o.batchId != 0) {
            if (!obj[o.batchId]) obj[o.batchId] = []
            obj[o.batchId].push(o)
          }
        })
        this.list = this.list.map(o => {
          if (o.batchId && o.batchId != 0) {
            o.list = obj[o.batchId] || []
            if (obj[o.batchId] && obj[o.batchId].length > 1) {
              obj[o.batchId] = []
            }
          } else {
            o.list = [o]
          }
          return o
        })
        console.log(this.list)
      })
    },
    hideDialog() {
      this.$emit('close')
    },
    load() {
      if (this.list.length >= this.totalItems) return
      this.pageParams.pageNo++
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.table{
  text-align: center;
  .table-header{
    background: #F5F6FB;
    padding: 12px 0;
  }
  .scroll-div{
    max-height: 500px;
    overflow: auto;
    .cell{
      padding: 12px 0;
    }
    .item{
      border-bottom: 1px solid #EFF0F3;
    }
  }
}
</style>