<template>
  <el-dialog
    title="修改状态"
    :visible.sync="visible"
    width="530px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="form-item flex flex-center">
        <span class="form-label">{{type === "hotel"?'酒店名称':'集团名称'}}</span>
        <el-input class="flex-1" :value="info.hotelName" disabled size="small"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">修改状态</span>
        <el-input class="flex-1" :value="'【'+info.statusOldName+'】修改为【'+info.statusName+'】'" disabled size="small"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label self-start">
          <span class="must">*</span>
          备注原因
        </span>
        <el-input type="textarea" class="flex-1" v-model="form.remark" size="small" maxlength="255" rows="5" show-word-limit placeholder="请输入备注原因"></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        确认
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { update_hotel_status, update_brand_status } from '@/api/hotel'
import { FormValid } from "@/utils/formValid";

const form = {
  brandId: '',
  hotelId: '',
  status: '',
  remark: '',
}
export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: () => {
        return 'hotel'
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.info.status) {
          this.form.hotelId = this.info.hotelId
          this.form.brandId = this.info.brandId
          this.form.status = this.info.status
        }
      } else {
        this.form = this.$deepClone(form)
        this.loading = false
      }
    }
  },
  computed: {
    checkFormList() {
      let arr = [
        {
          label: '备注原因',
          value: 'remark',
          methods: ['notEmpty']
        }
      ]
      return arr
    }
  },
  methods: {
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      this.loading = true
      if(this.type === 'hotel') {
        update_hotel_status(params).then(() => {
          this.loading = false
          this.$message.success('修改成功')
          this.$emit('confirm')
        })
      } else if (this.type === 'brand') {
        update_brand_status(params).then(() => {
          this.loading = false
          this.$message.success('修改成功')
          this.$emit('confirm')
        })
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 80px;
    text-align: right;
    padding-right: 20px;
  }
}

.tree-select{
  height: 30px;
  line-height: 30px;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  padding: 0 10px 0 15px;
  font-size: 13px;
}
</style>
<style lang="scss">
.date-picker-class .el-date-table .date-cell-highlight{
  span {
    color: red !important;
  }
}
</style>