<template>
  <el-dialog
    title=" "
    :visible.sync="visible"
    width="470px"
    custom-class="detail-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="detail-top flex flex-center">
        <div>
          <img :src="info.hotelLogo || logo" width="48" />
        </div>
        <div>
          <div class="hotel-name">
            {{info.hotelName}}
          </div>
          <div class="hotel-date">
            <span>
              开户时间
            </span>
            <span>
              {{info.enableTime}}
            </span>
          </div>
        </div>
      </div>
      <div class="hotel-info">
        <div v-if="type !== 'brand'">
           <div class="page-sub-title">
              酒店信息
            </div>
            <div class="form-item flex flex-center">
              <span class="form-label">
                类型：
              </span>
              <span class="flex-1">
                {{hotelTypeMap[info.hotelType]}}
              </span>
            </div>
            <div class="form-item flex flex-center">
              <span class="form-label">
                地址：
              </span>
              <span class="flex-1">
                {{info.provinceName}}
                {{info.cityName}}
                {{info.areaName}}
                {{info.hotelAddr}}
              </span>
            </div>
        </div>
        <div class="page-sub-title">
          联系人信息
        </div>
        <div class="form-item flex flex-center">
          <span class="form-label">
            姓名：
          </span>
          <span class="flex-1">
            {{info.contactPerson}}
          </span>
        </div>
        <div class="form-item flex flex-center">
          <span class="form-label">
            账号：
          </span>
          <span class="flex-1">
            {{info.accountUsername}}
          </span>
        </div>
        <div class="form-item flex flex-center">
          <span class="form-label">
            手机号码：
          </span>
          <span class="flex-1">
            {{info.contactPhone}}
          </span>
        </div>
        <div v-if="type !== 'brand'">
          <div class="page-sub-title">
            产品信息
          </div>
          <div class="form-item flex flex-center">
            <span class="flex-1 color">
              【{{info.systemName}} - {{info.productName}}】
            </span>
          </div>
          <div class="form-item flex flex-center">
            <span class="form-label">
              初装定价：
            </span>
            <span class="flex-1">
              {{$commaNum(info.firstPrice)}} 元
            </span>
          </div>
          <div class="form-item flex flex-center">
            <span class="form-label">
              年费定价：
            </span>
            <span class="flex-1">
              {{$commaNum(info.yearPrice)}} 元
            </span>
          </div>
          <div class="form-item flex flex-center">
            <span class="form-label">
              赠送短信：
            </span>
            <span class="flex-1">
              {{$commaNum(info.smsCount)}} 条
            </span>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" @click="hideDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_key_value } from '@/api/common'
import logo from '@/assets/icons/logo.png'

export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: () => {
        return 'hotel'
      }
    },
  },
  data() {
    return {
      logo,
      hotelTypeOption: [],
      hotelTypeMap: {}
    }
  },
  mounted() {
    this.getOption()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'HOTEL_TYPE'
      }).then(res => {
        this.hotelTypeOption = res ? res.records : []
        this.hotelTypeMap = this.$arrayToMap(this.hotelTypeOption)
      })
    },
    hideDialog() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  color: #333;
  & + .form-item{
    margin-top: 10px;
  }
  .form-label{
    color: #666;
  }
}
.color{
  color: $color
}
.page-sub-title{
  margin-top: 30px;
  margin-bottom: 15px;
}
.detail-top{
  margin: 0 -20px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 24px;
  img{
    margin-right: 14px;
  }
  .hotel-name{
    font-size: 20px;
    margin-bottom: 8px;
  }
  .hotel-date{
    font-size: 13px;
  }
}
.hotel-info{
  padding: 0 20px;
}
::v-deep .detail-dialog .el-dialog__header{
  border-bottom: 0;
  padding: 0;
}
</style>